<template>
  <div class="inpatient_container">
    <div class="header">
      <van-field readonly clickable label="选择住院人" :value="patName" @click="patNamePickerShow = true"
        right-icon="arrow-down" />
      <van-popup v-model="patNamePickerShow" round position="bottom">
        <van-picker show-toolbar :columns="patNameList" @cancel="patNamePickerShow = false" @confirm="patNameConfirm" />
      </van-popup>
    </div>
    <div class="list">
      <div v-for="(item, index) in hospitalizationRecords" :key="index" class="item"
        @click="navList(item.admissionNum, item.inDate)">
        <div>住院科室：{{ item.deptName }}</div>
        <div>入院日期：{{ item.inDate }}</div>
        <div>出院日期：{{ item.outDate === '' || item.outDate === '0001-01-01' ? '-' : item.outDate }}</div>
        <div>住院按金余额：{{ item.balance }}元</div>
        <van-tag plain type="primary" size="large" class="status" v-if="item.status === '1'">在&nbsp;院</van-tag>
        <van-tag plain type="success" size="large" class="status" v-if="item.status === '2'">已&nbsp;出&nbsp;院</van-tag>
        <van-icon name="arrow" size="20px" />
      </div>
    </div>
    <van-empty description="暂无内容" v-if="hospitalizationRecords === ''" />
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Deposit",
  data () {
    return {
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      hospitalizationRecords: []
    };
  },
  created () {
    this.getMembers();
  },
  methods: {
    //获取所有建档人
    async getMembers () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getAllUseInfo",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (let i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(res.data[i].name);
        }
        this.patName = this.patNameList[this.patNameIndex];
        this.getHospitalizationRecords();
      } else {
        this.$toast.fail("暂无建档，请先建档");
        // setTimeout(() => {
        //   this.$router.push({
        //     path: "/file",
        //     query: {
        //       path: "deposit"
        //     }
        //   });
        // }, 2000);
      }
    },
    //获取住院记录
    async getHospitalizationRecords () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/his/getHospitalizationRecords",
        {
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          patCardType: this.patNameArray[this.patNameIndex].pat_card_type,
          patName: this.patNameArray[this.patNameIndex].name
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.hospitalizationRecords = res.data.item;
      } else {
        this.hospitalizationRecords = "";
        this.$toast.fail(res.msg);
      }
    },
    patNameConfirm (value) {
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      this.patNamePickerShow = false;
      this.getHospitalizationRecords();
    },
    navList (admissionNum, inDate) {
      this.$router.push({
        path: "/inpatient/list",
        query: {
          patCardType: this.patNameArray[this.patNameIndex].pat_card_type,
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          admissionNum: admissionNum,
          inDate
        }
      });
    }
  }
};
</script>

<style scope>
.inpatient_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
}

.inpatient_container .header .van-cell {
  border-bottom: 1px solid rgb(201, 191, 191);
  padding: 30px;
}

.inpatient_container .header .van-cell::after {
  border: 0;
}

.inpatient_container .header .van-field__label {
  text-align: left;
  font-size: 40px;
}

.inpatient_container .header .van-field__control {
  text-align: right;
  margin-right: -10px;
  font-size: 40px;
}

.inpatient_container .header .van-icon {
  font-size: 40px;
}

.inpatient_container .header .van-picker-column {
  font-size: 40px;
}

.inpatient_container .header .van-picker__cancel,
.inpatient_container .header .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}

.inpatient_container .list {
  padding-bottom: 60px;
  margin-bottom: 0;
}

.inpatient_container .list .item {
  position: relative;
  border-bottom: 1px solid #f5f5f5;
  padding: 20px 30px;
  font-size: 40px;
}

.inpatient_container .list .item div {
  padding: 15px 0;
}

.inpatient_container .list .item .status {
  position: absolute;
  top: 50%;
  right: 90px;
  transform: translateY(-50%);
  font-size: 40px;
}

.inpatient_container .van-tag--large {
  padding: 20px;
}

.inpatient_container .list .item .van-icon {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}
</style>